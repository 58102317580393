import './LetterBank.css';
import LetterBox from './LetterBox';

function LetterBank(props) {
    return (
        <div className="letter-bank">
            {props.letters.map((letter, index) => (
                <LetterBox className="letter-box" key={index} letter={letter} letterShadow={props.letterShadow}/>
            ))}
        </div>
    );
}

export default LetterBank;
