import './App.css';
import { useCallback, useEffect, useState } from 'react';
import GameBoard from './components/GameBoard';
import { getRandomLettersWithMinVowels } from './util';
import kWords from './words';
import ScreenWithHeader from './components/ScreenWithHeader';

const kMaxRoundTime = 30;
const kMaxRound = 3;
const kWordLength = 9;

const AppStage = Object.freeze({
  LANDING: "landing",
  GAME: "game",
  END: "end",
});

function App() {
  const [round, setRound] = useState(0);
  const [timer, setTimer] = useState(0);
  const [recapInProgress, setRecapInProgress] = useState(false);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [score, setScore] = useState(0);
  const [stage, setStage] = useState(AppStage.LANDING);
  const [bankLetters, setBankLetters] = useState([]);
  const [clientLetters, setClientLetters] = useState([]);
  const [clientWords, setClientWords] = useState([]);

  console.log("reload");

  function reset() {
    setRound(0);
    setTimer(0);
    setRecapInProgress(false);
    setTimerIntervalId(null);
    setScore(0);
    setStage(AppStage.LANDING);
    setBankLetters([]);
    setClientLetters([]);
    setClientWords([]);
  }

  function startGame() {
    console.log("hi")
    reset();
    setRound(1);
    setTimer(kMaxRoundTime);
    setStage(AppStage.GAME);
    setBankLetters(getRandomLetterObjects());

    handleTimerStart();
  }

  function endGame() {
    setStage(AppStage.END);
  }

  const handleTimerStart = () => {
    console.log("starting")
    setTimerIntervalId(setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000));
  };

  const handleTimerStop = useCallback(() => {
    clearInterval(timerIntervalId);
  }, [timerIntervalId]);

  const GetClientWordStrings = useCallback(() => {
    let clientWordStrings = [];
    for (let clientWord of clientWords) {
      clientWordStrings.push(clientWord.word.map((letter) => letter.value).join(""));
    }
    return clientWordStrings;
  }, [clientWords]);

  const getRandomLetterObjects = useCallback(() => {
    const kRandomLetters = getRandomLettersWithMinVowels(kWordLength, 1);
    let randomLetters = [];
    for (let i = 0; i < kRandomLetters.length; i++) {
      randomLetters.push({ id: i, value: kRandomLetters[i], multiplier: 1, used: false });
    }
    return randomLetters;
  }, []);

  const shuffleBankLetters = useCallback(() => {
    let newBankLetters = [];
    for (let i = 0; i < bankLetters.length; i++) {
      newBankLetters.push(bankLetters[i]);
    }
    for (let i = 0; i < newBankLetters.length; i++) {
      let j = Math.floor(Math.random() * newBankLetters.length);
      let temp = newBankLetters[i];
      newBankLetters[i] = newBankLetters[j];
      newBankLetters[j] = temp;
    }
    setBankLetters(newBankLetters);
  }, [bankLetters]);

  const formatClientLetters = useCallback(() => {
    let formattedClientLetters = [];
    for (let i = 0; i < clientLetters.length; i++) {
      formattedClientLetters.push(clientLetters[i]);
    }
    for (let i = clientLetters.length; i < kWordLength; i++) {
      formattedClientLetters.push({ used: true });
    }
    return formattedClientLetters;
  }, [clientLetters]);

  const updateGameStage = useCallback(() => {
    handleTimerStop();
    let newRecapInProgress = !recapInProgress;
    if (newRecapInProgress) {
      setTimer(null)
      setClientWords((clientWords) => [...clientWords, { word: clientLetters, score: clientLetters.length }]);
      if (kWords.has(clientLetters.map((letter) => letter.value).join("").toLowerCase())) {
        setScore((score) => score + clientLetters.length);
      }
    } else {
      if (round === kMaxRound) {
        setRecapInProgress(true);
        endGame();
        return;
      }
      setRound((round) => round + 1);
      setBankLetters(getRandomLetterObjects());
      setClientLetters([]);
      setTimer(kMaxRoundTime);
      handleTimerStart();
    }
    setRecapInProgress(newRecapInProgress);
  }, [clientLetters, recapInProgress, round, getRandomLetterObjects]);

  useEffect(() => {
    // Don't even think about starting the timer if we're not ending a round
    if (stage === AppStage.GAME && !recapInProgress && timer === 0) {
      updateGameStage();
    }
  }, [timer, updateGameStage, handleTimerStop, stage, getRandomLetterObjects]);

  const isValidLetter = useCallback((letter) => {
    if (clientLetters.length >= kWordLength) {
      return false;
    }
    for (let bankLetter of bankLetters) {
      if (bankLetter.value === letter && !bankLetter.used) {
        return true;
      }
    }
    return false;
  }, [bankLetters, clientLetters]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      let inputKey = event.key.toUpperCase();
      switch (stage) {
        case AppStage.LANDING:
          if (inputKey === "ENTER") {
            startGame();
          }
          return;
        case AppStage.END:
          if (inputKey === "ENTER") {
            reset();
          }
          return;
        case AppStage.GAME:
          if (inputKey === "ENTER") {
            updateGameStage();
          }
          if (recapInProgress) { // if recap is in progress, don't allow any keypresses
            console.log("Ignoring non-ENTER keypress because recap is in progress")
            return;
          }
          console.log(inputKey)
          if (inputKey === " ") {
            shuffleBankLetters();
            return;
          }
          if (inputKey === "BACKSPACE") {
            // remove from client letters
            if (clientLetters.length === 0) {
              return;
            }
            let lastLetterId = clientLetters[clientLetters.length - 1].id;
            setClientLetters((clientLetters) => clientLetters.slice(0, -1));
            console.log(lastLetterId);

            // add to letter bank
            for (let i = 0; i < bankLetters.length; i++) {
              if (bankLetters[i].id === lastLetterId) {
                bankLetters[i].used = false;
              }
            }
            return;
          }
          if (isValidLetter(inputKey)) {
            // remove from letter bank
            let maxMultiplierIndex = 0;
            let maxMultiplier = 0;
            for (let i = bankLetters.length - 1; i >= 0; i--) {
              if (bankLetters[i].value === inputKey && bankLetters[i].multiplier >= maxMultiplier && !bankLetters[i].used) {
                maxMultiplierIndex = i;
                maxMultiplier = bankLetters[i].multiplier;
              }
            }
            bankLetters[maxMultiplierIndex].used = true;
            console.log("maxMultiplierIndex: " + maxMultiplierIndex);
            console.log("letter: " + bankLetters[maxMultiplierIndex].id)

            // add to client letters
            let clientLetter = { id: bankLetters[maxMultiplierIndex].id, value: inputKey, multiplier: 1, used: false };
            setClientLetters((clientLetters) => [...clientLetters, clientLetter]);
          }
        default:
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [clientLetters, bankLetters, stage, recapInProgress, isValidLetter, shuffleBankLetters, updateGameStage, startGame]);

  const GetMostRecentRoundScore = () => {
    if (clientWords.length === 0) {
      return 0;
    }
    return clientWords[clientWords.length - 1].score;
  }


  return (
    <div className="app">
      {stage === AppStage.LANDING ?
        <ScreenWithHeader screen="landing" startGameFn={startGame} /> : null}
      {(stage === AppStage.GAME) ?
        <GameBoard
          bankLetters={bankLetters}
          clientLetters={formatClientLetters(clientLetters)}
          time={timer}
          maxRoundTime={kMaxRoundTime}
          round={round}
          recapInProgress={recapInProgress}
          score={score}
          mostRecentRoundScore={GetMostRecentRoundScore(clientWords)}
          updateGameStageFn={updateGameStage}
        /> : null}
      {stage === AppStage.END ?
        <ScreenWithHeader
          screen="end"
          score={score}
          reset={reset}
          startGameFn={startGame}
          clientWords={GetClientWordStrings()}
        />
        : null}

      {/* for debugging: */}
      {/* <p>Score: {score}</p>
      <p>Round: {round}</p>
      <p>Recap: {recapInProgress ? "true" : "false"}</p>
      <p>Time: {timer}</p>
      <p>Stage: {stage}</p>
      <div>Client letters: {
        clientLetters.map((letter) => {
          return "val: " + letter.value + ", id: " + letter.id + ", used: " + letter.used + " || ";
        })
      }</div>
      <div>Formatted client letters: {
        formatClientLetters(clientLetters).map((letter) => {
          return "val: " + letter.value + ", id: " + letter.id + ", used: " + letter.used + " || ";
        })
      }</div>
      <div>Bank letters: {
        bankLetters.map((letter) => {
          return "val: " + letter.value + ", id: " + letter.id + ", used: " + letter.used + " || ";
        })
      }</div>
      <p>Client Words: {
        GetClientWordStrings().map((word) => {
          return word + " || ";
        })
      }
      </p>
      <p>MRRS: {GetMostRecentRoundScore(clientWords)}</p> */}
    </div>
  );
}

export default App;
