const kLetterFrequencies = {
    "A": 8.17,
    "B": 1.49,
    "C": 2.78,
    "D": 4.25,
    "E": 12.70,
    "F": 2.23,
    "G": 2.02,
    "H": 6.09,
    "I": 6.97,
    "J": 0.15,
    "K": 0.77,
    "L": 4.03,
    "M": 2.41,
    "N": 6.75,
    "O": 7.51,
    "P": 1.93,
    "Q": 0.10,
    "R": 5.99,
    "S": 6.33,
    "T": 9.06,
    "U": 2.76,
    "V": 0.98,
    "W": 2.36,
    "X": 0.15,
    "Y": 1.97,
    "Z": 0.07
};

function getRandomLetter() {
    const letters = Object.keys(kLetterFrequencies);
    const frequencies = Object.values(kLetterFrequencies);
    const total = frequencies.reduce((a, b) => a + b, 0);
    const random = Math.random() * total;
    let i = 0;
    let sum = frequencies[i];
    while (sum < random) {
        i++;
        sum += frequencies[i];
    }
    return letters[i];
}

function getRandomLetters(num_letters) {
    let letters = [];
    for (let i = 0; i < num_letters; i++) {
        letters.push(getRandomLetter());
    }
    return letters;
}

function getRandomLettersWithMinVowels(num_letters, min_vowels) {
    let letters = [];
    let vowels = ["A", "E", "I", "O", "U"];
    let num_vowels = 0;
    for (let i = 0; i < min_vowels; i++) {
        let letter = getRandomLetter();
        if (vowels.includes(letter)) {
            letters.push(letter);
            num_vowels++;
        }
    }
    for (let i = num_vowels; i < num_letters; i++) {
        letters.push(getRandomLetter());
    }
    return letters;
}

export { getRandomLettersWithMinVowels };