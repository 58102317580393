import './ScoreUpdate.css';

function ScoreUpdate(props) {
    const kExclamations = [
        ":(", // 0
        "That's technically a word", // 1
        "You can do better...", // 2
        "Ok!", // 3
        "Nice!", // 4
        "Great!", // 5
        "Amazing!", // 6
        "Incredible!", // 7
        "Unbelievable!", // 8
        "You're a wizard!" // 9
    ]
    return (
        <div className="score-update">
            {"+" + props.mostRecentRoundScore + " " + kExclamations[props.mostRecentRoundScore]}
        </div>
    );
}

export default ScoreUpdate;
