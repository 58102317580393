import './StatsBlock.css';
import Clock from './Clock';

function StatsBlock(props) {
    return (
        <div className="stats-block">
            <table>
                <tbody>
                    <tr>
                        <td>Round</td>
                        <td className='val-col'>{props.round}</td>
                    </tr>
                    <tr>
                        <td>Score</td>
                        <td className='val-col'>{props.score}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default StatsBlock;
