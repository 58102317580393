import './EndScreen.css';
import IconButton from './IconButton';

const kShareUrl = "https://magicspelly.com/";

function EndScreen(props) {
    function formatWords() {
        let nonEmptyClientWords = props.clientWords.filter(word => word !== "");
        if (nonEmptyClientWords.length === 0) {
            return "Fizz!";
        }
        let formattedWords = "";

        for (let i = 0; i < nonEmptyClientWords.length; i++) {
            formattedWords += nonEmptyClientWords[i];
            if (i < nonEmptyClientWords.length - 1) {
                formattedWords += ", ";
            } else {
                formattedWords += "!";
            }
        }
        return formattedWords;
    }

    // Copies score to the clipboard
    function share(score) {
        navigator.clipboard.writeText(formatWords() + " I put a spell on you! Can you my score of " + score + "?\n" + kShareUrl);
    }

    return (
        <div className="end-screen">
            <p>{formatWords()}</p>
            Final Score:
            <p className="final-score">{props.score}</p>
            <IconButton icon="home" onClickFn={props.reset} />
            <IconButton icon="replay" onClickFn={props.startGameFn} />
            <IconButton icon="copy" onClickFn={() => share(props.score)} />
        </div>
    );
}

export default EndScreen;
