import './Clock.css';

function formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

function Clock(props) {
    return (
        <div className="clock">
            <div className="clock-body">
                <div className="clock-face">
                    <span className={props.time > 5 ? "" : "alert-text"}>{formatTime(props.time)}</span>
                </div>
            </div>
        </div>
    );
}

export default Clock;
