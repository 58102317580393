import './GameBoard.css';
import LetterBank from './LetterBank';
import StatsBlock from './StatsBlock';
import IconButton from './IconButton';
import Clock from './Clock';
import ScoreUpdate from './ScoreUpdate';

function GameBoard(props) {
    return (
        <div className="game-board">
            <div className="game-board-header">
                <div className="title">Magic Spelly</div>
                <div className="recap-container">
                    {props.recapInProgress ?
                        <div className="score-update-container">
                            <ScoreUpdate mostRecentRoundScore={props.mostRecentRoundScore}/>
                        </div>
                        : <div className="clock-container">
                            <Clock time={props.time}/>
                        </div>}
                </div>
                <StatsBlock
                    round={props.round}
                    score={props.score}
                    recapInProgress={props.recapInProgress}
                />
            </div>
            <LetterBank letters={props.clientLetters} letterShadow={true} />
            {props.recapInProgress ?
                <div className="continue-button-container">
                    <IconButton icon="arrow" onClickFn={props.updateGameStageFn} />
                </div>
                : <LetterBank letters={props.bankLetters} letterShadow={false} />}
        </div>
    );
}

export default GameBoard;
