import './LandingScreen.css';
import IconButton from './IconButton';
import { useEffect, useState, useCallback } from 'react';

const kAnimationFrames = [
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "stand_1",
    "stand_2",
    "raise_1",
    "raise_2",
    "raise_3",
    "raise_4",
    "point_1",
    "point_2",
    "point_1",
    "point_2",
    "point_1",
    "point_2",
    "point_1",
    "point_2",
    "raise_3",
    "raise_2",
    "raise_1",
];

function LandingScreen(props) {
    const [timer, setTimer] = useState(0);

    function GetAnimation() {
        return kAnimationFrames[timer % kAnimationFrames.length];
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer(prevTime => prevTime + 1); // Increment every second
        }, 200);

        // Cleanup function to clear interval on unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array to run only once


    return (
        <div className="landing-screen">
            <img className="wizard" src={"worzard/" + GetAnimation() + ".png"} alt="Wizard" />
            <IconButton icon="play" onClickFn={props.startGameFn} />
        </div>
    );
}

export default LandingScreen;
