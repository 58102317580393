import EndScreen from './EndScreen';
import LandingScreen from './LandingScreen';
import './ScreenWithHeader.css';

function ScreenWithHeader(props) {
    return (
        <div className="screen-with-header">
            <header className="header">
                <span>Magic Spelly</span>
            </header>
            {props.screen === "landing" ?
                <LandingScreen startGameFn={props.startGameFn} /> :
                <EndScreen
                    score={props.score}
                    reset={props.reset}
                    startGameFn={props.startGameFn}
                    clientWords={props.clientWords}
                />
            }
        </div>
    );
}

export default ScreenWithHeader;
