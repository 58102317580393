import './LetterBox.css';

function LetterBox(props) {

    function getContainerClassName() {
        let className = "letter-box-container";
        if (props.letterShadow) {
            className += " shadow";
        }
        return className;
    }

    return (
        <div className={getContainerClassName()}>
            {
                props.letter.used ? "" :
                <div className="letter-box">
                    <span>{props.letter.value}</span>
                </div>
            }
        </div>

    );
}

export default LetterBox;
