import './IconButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faRotateRight, faCopy, faPlay, faRightLong, } from '@fortawesome/free-solid-svg-icons'

function iconToFaIcon(icon) {
    switch (icon) {
        case "home":
            return faHouse;
        case "replay":
            return faRotateRight;
        case "copy":
            return faCopy;
        case "play":
            return faPlay;
        case "arrow":
            return faRightLong;
        default:
            return faHouse;
    }
}

function IconButton(props) {
    return (
        <div className="icon-button" onClick={props.onClickFn}>
            <FontAwesomeIcon icon={iconToFaIcon(props.icon)} />
        </div>
    );
}

export default IconButton;
